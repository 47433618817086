import React, { useState, useEffect } from "react";
import { FaHome, FaCalendarAlt,/* FaClock, FaComments, FaFileAlt, FaUsers,*/ FaStar, FaUser, FaSignOutAlt } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

const NavBar = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [authUser, setAuthUser] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("authUser"));
    console.log("Fetched authUser from localStorage:", user); 

    if (user && user.user && user.user.name) {
      console.log("User name found:", user.user.name); 
      setAuthUser(user.user);
    } else {
      console.log("No user data found in localStorage or data is improperly structured.");
    }
  }, []);

  const handleScroll = () => {
    setIsScrolled(window.scrollY > 50);
  };

  const handleMenuClick = (item) => {
    if (item.name === "Logout") {
      localStorage.clear();
      console.log("User logged out. LocalStorage cleared.");
      navigate("/");
    } else if (item.name === "Profile Settings") {
      navigate("/profile");
    } else if (item.name === "Home") {
      navigate("/home");
    } else if (item.name === "My Business") {
      navigate("/business-page");
    }
    else if (item.name === "Plans") {
      navigate("/plans");
    } else {
      alert(`${item.name} is clicked`);
    }
  };

  const menuItems = [
    { name: "Home", icon: <FaHome /> },
    { name: "Profile Settings", icon: <FaUser /> },
    { name: "My Business", icon: <FaCalendarAlt /> },
    // { name: "Schedule Timings", icon: <FaClock /> },
    // { name: "Chat", icon: <FaComments /> },
     { name: "Plans", icon: <FaStar /> },
    // { name: "Pages", icon: <FaFileAlt /> },
    // { name: "Admin", icon: <FaUsers /> },
    { name: "Logout", icon: <FaSignOutAlt /> },
  ];

  return (
    <>
      <nav
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          zIndex: 1000,
          backgroundColor: isScrolled ? "#3498db" : "transparent",
          transition: "background-color 0.3s ease",
          color: "#fff",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          height: "60px",
          boxSizing: "border-box",
        }}
      >
        <button
          style={{
            background: "none",
            border: "none",
            color: isMenuOpen ? "black" : "#fff",
            fontSize: "18px",
            cursor: "pointer",
          }}
          onClick={() => setIsMenuOpen(!isMenuOpen)}
        >
          ☰
        </button>

        <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
          {/* <button
            style={{
              background: "none",
              border: "none",
              color: "#fff",
              fontSize: "18px",
              cursor: "pointer",
            }}
            onClick={() => alert("Notifications clicked")}
          >
            🔔
          </button> */}

          {authUser ? (
            <button
              style={{
                background: "none",
                border: "none",
                color: "#fff",
                fontSize: "16px",
                cursor: "pointer",
                marginRight: "3px",
              }}
              onClick={() => navigate("/profile")}
            >
              {authUser.name}
            </button>
          ) : (
            <p>Fetching user details...</p>
          )}
        </div>
      </nav>

      {isMenuOpen && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            zIndex: 998,
          }}
          onClick={() => setIsMenuOpen(false)}
        />
      )}

      <div
        style={{
          position: "fixed",
          top: 0,
          left: isMenuOpen ? "0" : "-270px",
          width: "270px",
          height: "100%",
          backgroundColor: "white",
          color: "black",
          transition: "left 0.3s ease",
          zIndex: 999,
          display: "flex",
          flexDirection: "column",
          padding: "20px",
          boxSizing: "border-box",
        }}
      >
        <button
          style={{
            alignSelf: "flex-end",
            background: "none",
            border: "none",
            color: "black",
            fontSize: "15px",
            cursor: "pointer",
            marginBottom: "20px",
          }}
          onClick={() => setIsMenuOpen(false)}
        >
          Menu
        </button>
        <ul style={{ listStyle: "none", padding: 0, margin: 0 }}>
          {menuItems.map((item, index) => (
            <li
              key={index}
              style={{
                marginBottom: "15px",
                display: "flex",
                alignItems: "center",
                padding: "10px",
                borderRadius: "5px",
                cursor: "pointer",
                transition: "background-color 0.3s ease",
              }}
              onMouseOver={(e) => {
                e.currentTarget.style.backgroundColor = "#3498db";
                e.currentTarget.style.color = "white";
              }}
              onMouseOut={(e) => {
                e.currentTarget.style.backgroundColor = "transparent";
                e.currentTarget.style.color = "black";
              }}
              onClick={() => {
                handleMenuClick(item);
              }}
            >
              <span style={{ marginRight: "10px", fontSize: "18px" }}>{item.icon}</span>
              {item.name}
            </li>
          ))}
        </ul>
      </div>
    </>
  );
};

export default NavBar;





