import React, { useState, useEffect } from "react";
import "../styles/Plans.css"; 
import NavBar from "./NavBar";

const Plans = () => {
  const [packages, setPackages] = useState(null); // Initialize with `null`
  const [error, setError] = useState(null); // Handle API errors

  // Fetch packages from API
  useEffect(() => {
    console.log("Fetching packages...");
    fetch("https://apitest.kitezone.in/api/packages")
      .then((response) => {
        console.log("API Response Status:", response.status);
        return response.json(); // Parse JSON response
      })
      .then((data) => {
        console.log("API Response Data:", data); // Log the entire response
        if (data && data.success && Array.isArray(data.data)) {
          // Parse features from `description`
          const parsedPackages = data.data.map((pkg) => ({
            ...pkg,
            features: pkg.description
              ? pkg.description.split(/[\n\r]+/).map((str) => str.trim())
              : [], // Convert `description` to an array
          }));
          setPackages(parsedPackages); // Update state with parsed data
        } else {
          console.error("Unexpected response format:", data);
          setError("Unexpected response format. Please try again later.");
        }
      })
      .catch((error) => {
        console.error("Error fetching packages:", error);
        setError("An error occurred while fetching packages.");
      });
  }, []);

  if (error) {
    return <div className="error-message">{error}</div>;
  }

  if (!packages) {
    return <div className="loading-message">Loading packages...</div>;
  }
  const getAdditionalText = (id) => {
    switch (id) {
      case 2:
        return "Basic +";
      case 3:
        return "Premium +";
      case 4:
        return "Gold +";
      default:
        return null; 
    }
  };

  return (
    <>
    <NavBar/>
    <div style={{height:"60px", backgroundColor:"#3498db"}}></div>
    <div className="plans-container">
      {packages.map((pkg) => (
        <div
          className={`plan-card ${pkg.is_most_popular ? "most-popular" : ""}`}
          key={pkg.id}
        >
          <h3>{pkg.name} </h3>
          {getAdditionalText(pkg.id) && (
            <p className="additional-text">{getAdditionalText(pkg.id)}</p>
          )}
          <ul className="features-list">
            {pkg.features && pkg.features.length > 0 ? (
              pkg.features.map((feature, index) => (
                <li key={index}>{feature}</li>
              ))
            ) : (
              <li>No features listed</li>
            )}
          </ul>
          <button className="choose-plan-btn">Choose Plan</button>
        </div>
      ))}
    </div>
    </>
  );
};

export default Plans;