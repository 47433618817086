import React, { useState, useEffect } from "react";
import NavBar from "./NavBar";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import "../styles/BusinessPage.css";

const BusinessPage = () => {
  const [business, setBusiness] = useState(null);
  const [products, setProducts] = useState([]);
  const [editingProduct, setEditingProduct] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [error, setError] = useState(null);
  const [imageFile, setImageFile] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchBusinessData = async () => {
      const token = localStorage.getItem("authToken");
      if (!token) {
        setError("Authentication error. Please log in again.");
        navigate("/login");
        return;
      }

      try {
        const response = await axios.get(
          "https://apitest.kitezone.in/api/business-details",
          { headers: { Authorization: `Bearer ${token.trim()}` } }
        );

        if (response.data && response.data.business) {
          const businessData = response.data.business;
          setBusiness(businessData);
          try {
            const productResponse = await axios.get(
              `https://apitest.kitezone.in/api/business/${businessData.id}/products`,
              { headers: { Authorization: `Bearer ${token.trim()}` } }
            );
            console.log(productResponse.data.products)
            setProducts(productResponse.data.products || []);
          } catch {
            setProducts([]);
          }
        } else {
          setBusiness(null);
        }
      } catch {
        setError("Failed to fetch business data. Please try again later.");
        setBusiness(null);
      }
    };

    fetchBusinessData();
  }, [navigate]);

  const handleBusinessClick = () => {
    navigate("/addedit-business", { state: { business } });
  };

  const handleAddProduct = () => {
    setEditingProduct(null);
    setIsModalOpen(true);
  };

  const handleEditProduct = (product) => {
    setEditingProduct(product);
    setIsModalOpen(true);
  };

  const handleSaveProduct = async (product) => {
    const token = localStorage.getItem("authToken");
    if (!token || !business?.id) return;
  
    try {
      // Construct the payload
      const payload = {
        business_id: business.id,
        category: product.category,
        name: product.name,
        description: product.description || "", // Default to empty string if null/undefined
      };
  
      // Include offer-related fields only if the offer checkbox is checked
      if (product.offer) {
        if (!product.actual_price || !product.offer_price) {
          alert("Both Actual Price and Offer Price are required when Offer is checked.");
          return;
        }
        payload.offer = 1; // Send 1 for true
        payload.actual_price = parseFloat(product.actual_price);
        payload.offer_price = parseFloat(product.offer_price);
      }
  
      console.log("Payload:", payload);
  
      // Make the API request
      const response = editingProduct
        ? await axios.put(
            `https://apitest.kitezone.in/api/products/${editingProduct.id}`,
            payload,
            { headers: { Authorization: `Bearer ${token}` } }
          )
        : await axios.post(
            `https://apitest.kitezone.in/api/products`,
            payload,
            { headers: { Authorization: `Bearer ${token}` } }
          );
  
      if (response.data && response.data.product) {
        setProducts((prev) =>
          editingProduct
            ? prev.map((p) => (p.id === editingProduct.id ? response.data.product : p))
            : [...prev, response.data.product]
        );
      }
      setIsModalOpen(false);
    } catch (error) {
      console.error("Failed to save the product:", error.response?.data || error.message);
      alert("Failed to save the product. Please try again.");
    }
  };    

  const handleUploadImage = async (productId) => {
    const token = localStorage.getItem("authToken");
    if (!token || !imageFile) return;
  
    const formData = new FormData();
    formData.append("photo", imageFile);
  
    try {
      const response = await axios.post(
        `https://apitest.kitezone.in/api/products/${productId}/upload-image`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );
  
      if (response.data && response.data.image_url) {
        alert("Image uploaded successfully!");
        setImageFile(null);

        window.location.reload();
      }
    } catch {
      alert("Failed to upload the image. Please try again.");
    }
  };   

  const handleCancelProduct = () => {
    setIsModalOpen(false);
    setError(null);
  };
  const handleDeleteProduct = async (productId) => {
    const token = localStorage.getItem("authToken");
    if (!token) return;

    try {
      await axios.delete(`https://apitest.kitezone.in/api/products/${productId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      // Update the products list after successful deletion
      setProducts((prevProducts) =>
        prevProducts.filter((product) => product.id !== productId)
      );

      alert("Product deleted successfully.");
    } catch {
      alert("Failed to delete the product. Please try again.");
    }
  };

  return (
    <>
      <NavBar />
      <div style={{ backgroundColor: "#3498db", height: "60px" }}></div>
      <div className="business-container">
        <h2>My Business</h2>
        {error && <p className="error-message">{error}</p>}
        {business ? (
          <div className="business-details">
            <p><strong>Name:</strong> {business.name || "Not provided"}</p>
            <p><strong>Address:</strong> {business.address || "Not provided"}</p>
            <p><strong>Email:</strong> {business.email || "Not provided"}</p>
            <p><strong>Phone:</strong> {business.phone || "Not provided"}</p>
            <p><strong>Website:</strong> {business.website ? (
              <a href={business.website} target="_blank" rel="noopener noreferrer">{business.website}</a>
            ) : "Not provided"}</p>
            <p><strong>Nature:</strong> {business.nature || "Not provided"}</p>
            <p><strong>Category:</strong> {business.category|| "Not provided"}</p>
            <p><strong>Sub-category:</strong> {business.sub_category || "Not provided"}</p>
            <p><strong>Specializations:</strong> {business.others || "Not provided"}</p>
            <p><strong>Years in Business:</strong> {business.years || "Not provided"}</p>
            <p><strong>Number of Employees:</strong> {business.employee_no || "Not provided"}</p>
            <p><strong>Description:</strong> {business.description || "Not provided"}</p>
          </div>
        ) : (
          <p>No Business is added yet</p>
        )}
        <button className="edit-button" onClick={handleBusinessClick}>
          {business ? "Edit Business" : "Add Business"}
        </button>
      </div>

      <div className="product-container">
        <h3 style={{ textAlign: "center" }}>My Products</h3>
        <button className="add-product-button" onClick={handleAddProduct}>
          Add Product/Services
        </button>
        <div className="product-list">
        {products.length > 0 ? (
          products.map((product) => (
            <div key={product.id} className="product-card">
              <h3>{product.name}</h3>
              {product.category && <p><strong>Category:</strong> {product.category}</p>}
              {product.actual_price && <p><strong>Price:</strong> {product.actual_price}</p>}
              {product.offer_price && <p><strong>Offer Price:</strong> {product.offer_price}</p>}
              {product.description && <p><strong>Description:</strong> {product.description}</p>}
              {product.photo ? (
                <img
                  src={`https://apitest.kitezone.in/images/products/${product.photo}`}
                  alt={product.name}
                  className="product-image"
                />
              ) : (
                <p>No image available</p>
              )}
              <button
                className="edit-product-button"
                onClick={() => handleEditProduct(product)}
              >
                Edit
              </button>
              <button
                className="upload-image-button"
                onClick={() => handleUploadImage(product.id)}
              >
                Upload Image
              </button>
              <input
                type="file"
                accept="image/*"
                onChange={(e) => setImageFile(e.target.files[0])}
              />
              <button
                className="delete-product-button"
                onClick={() => handleDeleteProduct(product.id)}
                style={{ backgroundColor: "red", color: "white", marginTop: "10px" }}
              >
                Delete
              </button>
            </div>
          ))
        ) : (
          <p>No products added yet.</p>
        )}
        </div>
      </div>

      {isModalOpen && (
        <ProductModal
          product={editingProduct}
          onSave={handleSaveProduct}
          onCancel={handleCancelProduct}
        />
      )}
    </>
  );
};

const ProductModal = ({ product, onSave, onCancel }) => {
  const [formData, setFormData] = useState({
    name: product?.name || "",
    category: product?.category || "",
    description: product?.description || "",
    actual_price: product?.actual_price || "",
    offer_price: product?.offer_price || "",
    offer: product?.offer_price ? true : false, // Checkbox initial state
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (formData.offer) {
      if (!formData.actual_price || !formData.offer_price) {
        alert("Both Actual Price and Offer Price are required when Offer is checked.");
        return;
      }
    }

    onSave(formData);
  };

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <h3>{product ? "Edit Product" : "Add Product"}</h3>
        <form onSubmit={handleSubmit}>
          <label>
            Product/Service Name:
            <input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
            />
          </label>
          <label>
            Category:
            <select
              name="category"
              value={formData.category}
              onChange={handleChange}
              required
            >
              <option value="" disabled>
                Select Category
              </option>
              <option value="Product">Product</option>
              <option value="Services">Services</option>
            </select>
          </label>
          <label>
            Description:
            <textarea
              name="description"
              value={formData.description}
              onChange={handleChange}
              rows="3"
              className="styled-textarea"
              required
            />
          </label>
          <label>
            Offer:
            <input
              type="checkbox"
              name="offer"
              checked={formData.offer}
              onChange={(e) => setFormData({ ...formData, offer: e.target.checked })}
            />
          </label>
          {formData.offer && (
            <>
              <label>
                Actual Price:
                <input
                  type="number"
                  name="actual_price"
                  value={formData.actual_price}
                  onChange={(e) => setFormData({ ...formData, actual_price: e.target.value })}
                  required
                />
              </label>
              <label>
                Offer Price:
                <input
                  type="number"
                  name="offer_price"
                  value={formData.offer_price}
                  onChange={(e) => setFormData({ ...formData, offer_price: e.target.value })}
                  required
                />
              </label>
            </>
          )}
          <div className="modal-actions">
            <button type="submit" className="save-button">
              Save
            </button>
            <button type="button" onClick={onCancel} className="cancel-button">
              Cancel
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default BusinessPage;