import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import "../styles/AddEditBusinessPage.css";

const AddEditBusinessPage = () => {
  const { state } = useLocation();
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    name: "",
    address: "",
    email: "",
    phone: "",
    website: "",
    nature: "",
    category: "",
    sub_category: "",
    others: "",
    years: "",
    employee_no: "",
    description: "",
  });

  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);

  useEffect(() => {
    if (state && state.business) {
      setFormData({
        ...state.business,
        sub_category: state.business.sub_category || "",
        others: state.business.others || "",
      });
    }
  }, [state]);

  useEffect(() => {
    // Fetch categories on component mount
    const fetchCategories = async () => {
      try {
        const response = await axios.get("https://apitest.kitezone.in/api/categories");
        const updatedCategories = response.data.map((cat) => ({
          id: cat.id,
          name: cat.category_name,
        }));
        setCategories(updatedCategories);
        console.log("Fetched Categories:", updatedCategories); // Debug log
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };
    fetchCategories();
  }, []);

  useEffect(() => {
    // Fetch subcategories whenever a category is selected
    const fetchSubCategories = async () => {
      if (!formData.category) {
        setSubCategories([]);
        return; // Only fetch if a category is selected
      }
      try {
        const response = await axios.get(
          `https://apitest.kitezone.in/api/subcategories/${formData.category}`
        );
        const updatedSubCategories = response.data.map((subCat) => ({
          id: subCat.id,
          name: subCat.category_name,
        }));
        setSubCategories(updatedSubCategories);
        console.log(
          "Fetched Subcategories for category ID",
          formData.category,
          ":",
          updatedSubCategories
        ); // Debug log
      } catch (error) {
        console.error("Error fetching subcategories:", error);
      }
    };
    fetchSubCategories();
  }, [formData.category]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
      ...(name === "category" && { sub_category: "" }), // Clear sub-category when category changes
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    const authUser = JSON.parse(localStorage.getItem("authUser"));
    const token = localStorage.getItem("authToken");
    const userId = authUser?.user?.id;
  
    if (!token || !userId) {
      alert("Authentication error. Please log in again.");
      console.error("No authentication token or user ID found.");
      return;
    }
  
    if (!/^[A-Za-z\s]+$/.test(formData.name)) {
      alert("The business name must only contain letters and spaces.");
      return;
    }
  
    if (!formData.years || parseInt(formData.years) <= 0) {
      alert("Years in business must be a positive number.");
      return;
    }
  
    if (!formData.description || formData.description.trim().length < 1) {
      alert("Description must be at least 10 characters long.");
      return;
    }
  
    // Prepare payload
    const { image, ...payloadWithoutImage } = formData; // Remove the `image` field
    const payload = {
      ...payloadWithoutImage,
      user_id: userId,
      sub_category: formData.sub_category || "N/A",
      website: formData.website || "",
    };
  
    try {
      console.log("Payload being sent:", payload);
  
      const response = state?.business
        ? await axios.put(
            `https://apitest.kitezone.in/api/business/update/${state.business.id}`,
            payload,
            { headers: { Authorization: `Bearer ${token}` } }
          )
        : await axios.post(
            `https://apitest.kitezone.in/api/business/register`,
            payload,
            { headers: { Authorization: `Bearer ${token}` } }
          );
  
      console.log("Business saved successfully:", response.data);
      alert("Business details saved successfully!");
      navigate("/business-page");
    } catch (error) {
      if (error.response) {
        console.error("Error Response:", error.response.data);
        alert(`Error: ${error.response.data.message || "Failed to save business details"}`);
      } else {
        console.error("Error saving business details:", error.message);
        alert("An unexpected error occurred.");
      }
    }
  };  

  return (
    <div className="add-edit-business-container">
      <h2>{state?.business ? "Edit Business" : "Add Business"}</h2>
      <form className="business-form" onSubmit={handleSubmit}>
        <label>
          Business Name:
          <input
            type="text"
            name="name"
            value={formData.name || ""}
            onChange={handleInputChange}
            required
          />
        </label>
        <label>
          Address:
          <input
            type="text"
            name="address"
            value={formData.address || ""}
            onChange={handleInputChange}
            required
          />
        </label>
        <label>
          Email:
          <input
            type="email"
            name="email"
            value={formData.email || ""}
            onChange={handleInputChange}
            required
          />
        </label>
        <label>
          Phone:
          <input
            type="text"
            name="phone"
            value={formData.phone || ""}
            onChange={handleInputChange}
            required
          />
        </label>
        <label>
          Website:
          <input
            type="url"
            name="website"
            placeholder="https://www.business.com"
            value={formData.website || ""}
            onChange={handleInputChange}
          />
        </label>
        <label>
          Nature of Business:
          <input
            type="text"
            name="nature"
            value={formData.nature || ""}
            onChange={handleInputChange}
            required
          />
        </label>
        <label>
          Category:
          <select
            name="category"
            value={formData.category || ""}
            onChange={handleInputChange}
            required
          >
            <option value="">Select a category</option>
            {categories.map((category) => (
              <option key={category.id} value={category.id}>
                {category.name}
              </option>
            ))}
          </select>
        </label>
        <label>
          Sub-category:
          <select
            name="sub_category"
            value={formData.sub_category || ""}
            onChange={handleInputChange}
          >
            <option value="">Select a sub-category</option>
            {subCategories.map((subCategory) => (
              <option key={subCategory.id} value={subCategory.id}>
                {subCategory.name}
              </option>
            ))}
          </select>
        </label>
        <label>
          Other Specializations:
          <input
            type="text"
            name="others"
            value={formData.others || ""}
            onChange={handleInputChange}
          />
        </label>
        <label>
          Years in Business:
          <input
            type="number"
            name="years"
            value={formData.years || ""}
            onChange={handleInputChange}
          />
        </label>
        <label>
          Number of Employees:
          <input
            type="number"
            name="employee_no"
            value={formData.employee_no || ""}
            onChange={handleInputChange}
          />
        </label>
        <label>
          Description:
          <textarea
            name="description"
            value={formData.description || ""}
            onChange={handleInputChange}
          />
        </label>
        <button type="submit">
          {state?.business ? "Update Business" : "Add Business"}
        </button>
      </form>
    </div>
  );
};

export default AddEditBusinessPage;