import React, { useState, useEffect } from "react";
import "../styles/ProfilePage.css";
import NavBar from "./NavBar";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const ProfilePage = () => {
  const [user, setUser] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const authUser = JSON.parse(localStorage.getItem("authUser"));
    if (authUser && authUser.user) {
      setUser({
        ...authUser.user,
        image: authUser.user.image
          ? authUser.user.image.startsWith("http")
            ? authUser.user.image
            : `https://apitest.kitezone.in/images/users/${authUser.user.image}`
          : "https://kitezone.in/images/users/user_pic_1725948652.png",
      });
    } else {
      console.error("No user data found in localStorage or data is improperly structured.");
    }
  }, []);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    console.log("Selected file:", file);
    setSelectedFile(file);
  };

  const handleUpload = async () => {
    if (!selectedFile) {
      alert("Please select a file first.");
      return;
    }
  
    const formData = new FormData();
    formData.append("image", selectedFile);
  
    const token = localStorage.getItem("authToken");
  
    if (!token || !user?.id) {
      alert("Authentication error. Please log in again.");
      navigate("/login");
      return;
    }
  
    console.log("Uploading file...");
    setIsUploading(true);
  
    try {
      const response = await axios.post(
        `https://apitest.kitezone.in/api/upload-profile-picture/${user.id}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );
    
      console.log("Upload successful. Response:", response.data);
    
      // Update the user state with the new image URL immediately
      const updatedUser = { ...user, image: response.data.image_url };
      setUser(updatedUser);
    
      // Update the localStorage with the new user data
      localStorage.setItem("authUser", JSON.stringify({ user: updatedUser }));
    
      alert("Profile picture updated successfully!");
      setSelectedFile(null);
    } catch (error) {
      console.error("Error uploading profile picture:", error.response || error);
      alert(
        error.response?.data?.message || "Failed to upload profile picture. Please try again."
      );
    }
     finally {
      setIsUploading(false);
    }
  };
  

  const handleEditClick = () => {
    navigate("/edit-profile");
  };

  const handleBusinessPageClick = () => {
    navigate("/business-page");
  };

  return (
    <>
      <NavBar />
      <div style={{ backgroundColor: "#3498db", height: "60px" }}></div>
      <div className="profile-container">
        {user ? (
          <div className="profile-card">
            <div className="profile-picture">
              <label htmlFor="file-upload" className="file-upload-label">
                <img
                  src={user.image}
                  alt="Profile"
                  className="profile-img"
                />
              </label>
              <input
                id="file-upload"
                type="file"
                accept="image/*"
                onChange={handleFileChange}
                style={{ display: "none" }}
              />
            </div>

            {selectedFile && (
              <button
                className="upload-button"
                onClick={handleUpload}
                disabled={isUploading}
              >
                {isUploading ? "Uploading..." : "Upload Profile Picture"}
              </button>
            )}

            <h2 className="profile-name">{user.name}</h2>

            <div className="profile-icons">
              <a href={`tel:${user.phone}`} className="icon-button">
                <i className="fas fa-phone"></i>
              </a>
              <a href={`mailto:${user.email}`} className="icon-button">
                <i className="fas fa-envelope"></i>
              </a>
            </div>

            <div className="profile-details">
              <p>{user.address || "Address not provided"}</p>
              <p>{user.district || "District not provided"}</p>
            </div>

            <button className="edit-button" onClick={handleEditClick}>
              Edit Profile
            </button>

            <button
              className="business-button"
              onClick={handleBusinessPageClick}
            >
              Go to My Business
            </button>
          </div>
        ) : (
          <p>Loading user data...</p>
        )}
      </div>
    </>
  );
};

export default ProfilePage;