import React, { useState } from "react";
import "../styles/Register.css";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import axios from "axios";

function RegistrationPage() {
  const [showPassword, setShowPassword] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [phone, setPhone] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (password !== confirmPassword) {
      setError("Passwords do not match");
      return;
    }

    const userData = {
      name,
      email,
      password,
      phone,
      user_type: 1,
      package_id: null,
    };

    try {
      const response = await axios.post("https://apitest.kitezone.in/api/register", userData);
      console.log("User registered successfully:", response.data);
      navigate("/");
    } catch (error) {
      console.error("Registration error:", error);
      setError("Failed to register. Please try again.");
    }
  };

  return (
    <div className="body1">
      <div className="register-container">
        <div className="register">
          <h2>Register</h2>
          <p>Enter your Details to Register with us</p>
          <div style={{ display: "flex", alignItems: "center", margin: "20px 0" }}>
            <hr style={{ flex: 1, border: "none", borderTop: "1px solid #ccc" }} />
          </div>

          {error && <div style={{ color: "red", fontSize: "small" }}>{error}</div>}

          <form onSubmit={handleSubmit}>
            <div className="name">
              <label htmlFor="name">Name:</label>
              <input
                type="text"
                id="name"
                placeholder="Enter your name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
              />
            </div>
            <div className="email">
              <label htmlFor="email">E-mail:</label>
              <input
                type="email"
                id="email"
                placeholder="Enter your email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
            <div className="pass">
                <label htmlFor="password">Password:</label>
                <div className="input-container">
                    <input
                    type={showPassword ? "text" : "password"}
                    id="password"
                    placeholder="Enter new password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                    />
                    <span
                    className="toggler"
                    onClick={() => setShowPassword(!showPassword)}
                    >
                    {showPassword ? <FaEyeSlash /> : <FaEye />}
                    </span>
                </div>
                </div>
                <div className="pass">
                <label htmlFor="confirmPassword">Confirm Password:</label>
                <div className="input-container">
                    <input
                    type={showPassword ? "text" : "password"}
                    id="confirmPassword"
                    placeholder="Re-enter password"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    required
                    />
                    <span
                    className="toggler"
                    onClick={() => setShowPassword(!showPassword)}
                    >
                    {showPassword ? <FaEyeSlash /> : <FaEye />}
                    </span>
                </div>
                </div>

            <div className="phone">
              <label htmlFor="phone">Phone:</label>
              <input
                type="text"
                id="phone"
                placeholder="Enter your phone number"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                required
              />
            </div>
            <button type="submit" className="btn-s">
              Register
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default RegistrationPage;
