import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import "../styles/Login.css";
import { FaEye, FaEyeSlash } from "react-icons/fa";
// import { GoogleLogin } from "@react-oauth/google";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();

    const loginData = {
      email,
      password,
    };
    console.log(loginData);
    
    try {
      const response = await axios.post(
        "https://apitest.kitezone.in/api/login",
        loginData
      );
      console.log("Login successful:", response.data);
      const token = response.data.token; 
      localStorage.setItem("authToken", token);
      const userResponse = await axios.get(
        "https://apitest.kitezone.in/api/user",
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      localStorage.setItem("authUser", JSON.stringify(userResponse.data));
      navigate("/home"); 
    } catch (err) {
      console.error("Login error:", err.response?.data || err.message);
      setError(err.response?.data?.message || "Invalid credentials");
    }
  };

//   const handleGoogleLogin = async (credentialResponse) => {
//     console.log("Google Credential:", credentialResponse);

//     try {
//       const response = await axios.post(
//         "http://127.0.0.1:8000/api/google-login",
//         { token: credentialResponse.credential }
//       );

//       const token = response.data.token; // Assuming the backend returns a JWT token
//       localStorage.setItem("authToken", token);
//       navigate("/home"); 
//     } catch (err) {
//       console.error("Google Login Error:", err);
//       setError("Failed to sign in with Google");
//     }
  // };

  return (
    <div className="body1">
    <div className="login-container">
      <div className="login">
        <h2>Welcome</h2>
        <p>Please enter your details to sign in</p>

        {/* Google Login Button
        <GoogleLogin
          onSuccess={handleGoogleLogin}
          onError={() => {
            console.error("Google Login Failed");
            setError("Google Login Failed");
          }}
        /> */}
        {/* <button className='btn-g'> Continue with Google</button>

        <div style={{ display: "flex", alignItems: "center", margin: "20px 0" }}>
          <hr style={{ flex: 1, border: "none", borderTop: "1px solid #ccc" }} />
          <span style={{ margin: "0 10px", color: "#555" }}>or</span>
          <hr style={{ flex: 1, border: "none", borderTop: "1px solid #ccc" }} />
        </div> */}

        {error && <div style={{ color: "red", fontSize: "small" }}>{error}</div>}

        {/* Email/Password Login */}
        <form onSubmit={handleLogin}>
          <div className="username">
            <label htmlFor="email">E-mail:</label>
            <input
              type="email"
              id="email"
              placeholder="Enter your email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div className="pass1">
            <label htmlFor="password">Password:</label>
            <input
              type={showPassword ? "text" : "password"}
              id="password"
              placeholder="Enter your password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
            <span
              className="toggle"
              onClick={() => setShowPassword(!showPassword)}
            >
              {showPassword ? <FaEyeSlash /> : <FaEye />}
            </span>
          </div>
          <button type="submit" className="btn-s">
            Sign in
          </button>
          <button className="reg" onClick={()=>{
            setEmail("");
            setPassword(""); 
            navigate('/register')}}>Register Now</button>
        </form>
      </div>
    </div>
    </div>
  );
};

export default Login;