import React, { useContext, useEffect, useState } from 'react';
import Slider from 'react-slick';
import '../styles/Home.css';
import NavBar from './NavBar';
import { useNavigate } from 'react-router-dom';
import { userContext } from './ApiFetchContext';
import { FaSearch } from 'react-icons/fa';

const Home = () => {
  const { userData: contextUsers, businessData } = useContext(userContext);
  const [randomUsers, setRandomUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    if (contextUsers && contextUsers.length > 0) {
      console.log('Fetched Data from Context:', contextUsers);
      setRandomUsers(contextUsers); // Use context users directly
      setLoading(false);
    } else {
      console.warn('No users found in Context. Checking localStorage...');
      const usersFromStorage = JSON.parse(localStorage.getItem('userContext')) || [];
      if (usersFromStorage.length > 0) {
        console.log('Fetched Data from LocalStorage:', usersFromStorage);
        setRandomUsers(usersFromStorage);
      } else {
        console.warn('No users found in LocalStorage either.');
      }
      setLoading(false);
    }
  }, [contextUsers]);

  const handleSearch = () => {
    const lowerQuery = searchQuery.toLowerCase();

    const filteredUsers =
      randomUsers.filter(
        (user) =>
          user.name?.toLowerCase().includes(lowerQuery) ||
          user.email?.toLowerCase().includes(lowerQuery) ||
          user.address?.toLowerCase().includes(lowerQuery) ||
          user.district?.toLowerCase().includes(lowerQuery)
      ) || [];

    const filteredBusinesses =
      businessData.filter(
        (business) =>
          business.name?.toLowerCase().includes(lowerQuery) ||
          business.email?.toLowerCase().includes(lowerQuery) ||
          business.address?.toLowerCase().includes(lowerQuery) ||
          business.description?.toLowerCase().includes(lowerQuery) ||
          business.category?.toLowerCase().includes(lowerQuery) ||
          business.nature?.toLowerCase().includes(lowerQuery)
      ) || [];

    navigate('/search-results', {
      state: { users: filteredUsers, businesses: filteredBusinesses },
    });
  };

  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 1200,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <>
      <NavBar />
      <div className="head">
        <div className="section-one">
          <h1 style={{ color: 'white', fontFamily: "'Times New Roman', Times, serif" }}>
            BAP Volume 5
          </h1>
          <div className="search-bar">
            <input
              type="text"
              placeholder="Search users or businesses..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              onKeyDown={(e) => e.key === 'Enter' && handleSearch()}
              className="search-input"
            />
            <button onClick={handleSearch} className="search-button">
              <FaSearch style={{ color: 'white', fontSize: '16px' }} />
            </button>
          </div>
        </div>
        <div style={{ marginTop: '10px', fontStyle: 'oblique', fontSize: '20px', marginLeft: '10px' }}>
          Members
        </div>
        <div className="section-two">
          <div className="carousel-container">
            {loading ? (
              <p>Loading data...</p>
            ) : randomUsers && randomUsers.length > 0 ? (
              <Slider {...settings}>
                {randomUsers.map((user) => (
                  <div
                    key={user.id}
                    className="carousel-card"
                    onClick={() => navigate(`/user/${user.id}`)}
                  >
                    <div className="card-content">
                      <img
                        src={
                          user.image
                            ? `https://apitest.kitezone.in/images/users/${user.image}`
                            : 'https://kitezone.in/images/users/user_pic_1725948652.png'
                        }
                        alt={user.name || 'No Name'}
                        className="card-img"
                      />
                      <div className="card-info">
                        <h3>{user.name || 'Name Not Available'}</h3>
                        <p>{user.email || 'E-mail Not Available'}</p>
                        <p>{user.district || 'Location Not Available'}</p>
                      </div>
                    </div>
                  </div>
                ))}
              </Slider>
            ) : (
              <p>No members found.</p>
            )}
          </div>
          <div className="view">
            <button
              onClick={() => {
                navigate('/more-members');
              }}
            >
              View More
            </button>
          </div>
        </div>

        <div className="section-three">
          <div
            style={{ marginTop: '10px', fontStyle: 'oblique', fontSize: '20px', marginLeft: '10px' }}
          >
            Business
          </div>
          <div className="carousel-container">
            {businessData && businessData.length > 0 ? (
              <Slider {...settings}>
                {businessData.map((b) => (
                  <div
                    key={b.id}
                    className="carousel-card"
                    onClick={() => navigate(`/user/${b.id}`)}
                  >
                    <div className="card-content-b">
                      <div className="card-info">
                        <h3>{b.name || 'Name Not Available'}</h3>
                        <p>{b.email || 'E-mail Not Available'}</p>
                        <p>{b.category || 'Location Not Available'}</p>
                      </div>
                    </div>
                  </div>
                ))}
              </Slider>
            ) : (
              <p>Loading data...</p>
            )}
          </div>
          <div className="view">
            <button
              onClick={() => {
                navigate('/more-business');
              }}
            >
              View More
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;