import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import "../styles/UserDetails.css";
import NavBar from "./NavBar";

const UserDetailsPage = () => {
  const { userId } = useParams();
  const [user, setUser] = useState(null);
  const [business, setBusiness] = useState(null);
  const [error, setError] = useState(null);

  const userBaseUrl = "https://apitest.kitezone.in/api/user";
  const businessBaseUrl = "https://apitest.kitezone.in/api/business-details";

  useEffect(() => {
    const fetchDetails = async () => {
      try {
        console.log(`Fetching user details for ID: ${userId}`);
        const userResponse = await axios.get(`${userBaseUrl}/${userId}`);
        console.log("User Response:", userResponse.data);
        setUser(userResponse.data.user);
      } catch (error) {
        if (error.response && error.response.status === 404) {
          setError("User not found.");
          console.error("Error fetching user:", error.response);
        } else {
          setError("Unable to fetch user details.");
          console.error("Error fetching user:", error.message);
        }
        return;
      }

      try {
        console.log(`Fetching business details for user ID: ${userId}`);
        const businessResponse = await axios.get(`${businessBaseUrl}/${userId}`);
        console.log("Business Response:", businessResponse.data);
        setBusiness(businessResponse.data.data || null); 
      } catch (error) {
        if (error.response && error.response.status === 404) {
          console.log("No business details available for this user.");
          setBusiness(null); 
        } else {
          console.error("Error fetching business:", error.message);
        }
      }
    };

    fetchDetails();
  }, [userId]);

  if (error) return <div className="error-message">{error}</div>;
  if (!user) return <div className="loading-message">Loading user details...</div>;

  return (
    <>
      <NavBar />
      <div style={{ backgroundColor: "#3498db", height: "60px" }}></div>
      <div className="user-details-container">
        <h2>User Details</h2>
        <div className="profile-picture">
          <img
            src={
              user.image
                ? `https://apitest.kitezone.in/images/users/${user.image}`
                : "https://kitezone.in/images/users/user_pic_1725948652.png"
            }
            alt="Profile"
          />
        </div>

        <h2 className="profile-name">{user.name}</h2>

        <div className="profile-icons">
          <a href={`tel:${user.phone}`} className="icon-button">
            <i className="fas fa-phone"></i>
          </a>
          <a
            href={`https://wa.me/${user.phone}`}
            target="_blank"
            rel="noopener noreferrer"
            className="icon-button"
          >
            <i className="fab fa-whatsapp"></i>
          </a>
          <a href={`mailto:${user.email}`} className="icon-button">
            <i className="fas fa-envelope"></i>
          </a>
        </div>

        <div className="profile-details">
          <p>{user.address || "Address not provided"}</p>
          <p>{user.district || "District not provided"}</p>
        </div>

        {business ? (
          <>
            <h3>Business Details</h3>
            <div className="business-details">
              <p><strong>Business Name:</strong> {business.name || "No Business Name"}</p>
              <p><strong>Address:</strong> {business.address || "Address not provided"}</p>
              <p><strong>Description:</strong> {business.description || "No description available"}</p>
              <p><strong>Category:</strong> {business.category || "Category not provided"}</p>
              <p><strong>Sub-Category:</strong> {business.sub_category || "Sub-category not provided"}</p>
              <p><strong>Products:</strong> {Array.isArray(business.products) ? business.products.join(", ") : "No products listed"}</p>
            </div>
          </>
        ) : (
          <p style={{ color: "gray", fontStyle: "italic" }}>No business details available for this user.</p>
        )}
      </div>
    </>
  );
};

export default UserDetailsPage;