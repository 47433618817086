import React, { createContext, useState, useEffect } from 'react';
import axios from 'axios';

export const userContext = createContext();

function ApiFetchContext({ children }) {
  const [userData, setUserData] = useState([]);
  const [businessData, setBusinessData] = useState([]);
  const group = 5; 
  const userUrl = `https://apitest.kitezone.in/api/users?group=${group}`;
  const businessUrl = `https://apidata.kitezone.in/api/businesses`;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const userResponse = await axios.get(userUrl);
        console.log("Fetched Data:", userResponse.data); 
        setUserData(userResponse.data); 
        localStorage.setItem("userContext", JSON.stringify(userResponse.data)); 
        const businessResponse = await axios.get(businessUrl);
        console.log("Fetched Business Data:", businessResponse.data);
        setBusinessData(businessResponse.data);
        localStorage.setItem("businessContext", JSON.stringify(businessResponse.data));
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    };
    fetchData();
  }, []);

  return (
    <userContext.Provider value={{userData, businessData}}>
      {children}
    </userContext.Provider>
  );
}

export default ApiFetchContext;


