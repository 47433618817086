import React, { useContext } from 'react';
import { userContext } from './ApiFetchContext';
import '../styles/MoreMentors.css';
import Navbar from './NavBar';
import { useNavigate } from 'react-router-dom';

const MoreMentors = () => {
  const {userData} = useContext(userContext);
  const navigate = useNavigate();

  return (
    <>
      <Navbar />
      <div style={{height:"60px", backgroundColor:"#3498db"}}></div>
      <div className="more-mentors-container">
        <h2>All Members</h2>
        <div className="mentor-cards">
          {userData && userData.length > 0 ? (
            userData.map((user) => (
              <div key={user.id} className="mentor-card">
                <div className="mentor-card-header">
                  <img
                    src={user.image ? ` https://apitest.kitezone.in/images/users/${user.image}` :'https://kitezone.in/images/users/user_pic_1725948652.png'}
                    alt={user.name || 'No Name'}
                    className="mentor-card-img"
                  />
                </div>
                <div className="mentor-card-info">
                  <h3>{user.name || 'Name Not Available'}</h3>
                  <p>
                    {user.email ? (
                      <a href={`mailto:${user.email}`} style={{ textDecoration: 'none', color: 'inherit' }}>
                        {user.email}
                      </a>
                    ) : (
                      'Email Not Available'
                    )}
                  </p>
                  <p>
                    {user.phone ? (
                      <a
                        href={`https://wa.me/${user.phone}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ textDecoration: 'none', color: 'inherit' }}
                      >
                        {user.phone}
                      </a>
                    ) : (
                      'Phone Not Available'
                    )}
                  </p>
                  <button className="view-profile-btn" onClick={() => navigate(`/user/${user.id}`)}>View</button>
                </div>
              </div>
            ))
          ) : (
            <p>Loading data.......</p>
          )}
        </div>
      </div>
    </>
  );
};

export default MoreMentors;