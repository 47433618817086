import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "../styles/SearchResults.css";
import NavBar from "./NavBar";

const SearchResultsPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { users = [], businesses = [] } = location.state || {};

  return (
    <>
    <NavBar/>
    <div style={{height:"60px", backgroundColor:"#3498db"}}></div>
    <div className="search-results-container">
      <h2>Search Results</h2>

      {users.length > 0 && (
        <>
          <h3>Users</h3>
          <div className="more-mentors-container">
          <div className="mentor-cards">
            {users.map((user) => (
            <div key={user.id} className="mentor-card">
            <div className="mentor-card-header">
              <img
                src={user.image ? `https://kitezone.in/images/users/${user.image}` :'https://kitezone.in/images/users/user_pic_1725948652.png'}
                alt={user.name || 'No Name'}
                className="mentor-card-img"
              />
            </div>
            <div className="mentor-card-info">
              <h3>{user.name || 'Name Not Available'}</h3>
              <p>
                {user.email ? (
                  <a href={`mailto:${user.email}`} style={{ textDecoration: 'none', color: 'inherit' }}>
                    {user.email}
                  </a>
                ) : (
                  'Email Not Available'
                )}
              </p>
              <p>
                {user.phone ? (
                  <a
                    href={`https://wa.me/${user.phone}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ textDecoration: 'none', color: 'inherit' }}
                  >
                    {user.phone}
                  </a>
                ) : (
                  'Phone Not Available'
                )}
              </p>
              <button className="view-profile-btn" onClick={() => navigate(`/user/${user.id}`)}>View</button>
            </div>
          </div>
            ))}
          </div>
          </div>
        </>
      )}

      {businesses.length > 0 && (
        <>
          <h3>Businesses</h3>
          <div className="mentor-cards">
            {businesses.map((b) => (
             <div key={b.id} className="mentor-card"
             onClick={() => navigate(`/user/${b.id}`)}>
               {/* <div className="mentor-card-header">
                 <img
                   src={user.image || 'https://kitezone.in/images/users/user_pic_1725948652.png'}
                   alt={user.name || 'No Name'}
                   className="mentor-card-img"
                 />
               </div> */}
               <div className="mentor-card-info">
                 <h3>{b.name || 'Name Not Available'}</h3>
                 <p>
                   {b.email ? (
                     <a href={`mailto:${b.email}`} style={{ textDecoration: 'none', color: 'inherit' }}>
                       {b.email}
                     </a>
                   ) : (
                     'Email Not Available'
                   )}
                 </p>
                 {/* <p>
                   {user.phone ? (
                     <a
                       href={`https://wa.me/${user.phone}`}
                       target="_blank"
                       rel="noopener noreferrer"
                       style={{ textDecoration: 'none', color: 'inherit' }}
                     >
                       {user.phone}
                     </a>
                   ) : (
                     'Phone Not Available'
                   )}
                 </p> */}
                 <p>{b.category || 'Description Not Available'}</p>
               </div>
             </div>
            ))}
          </div>
        </>
      )}

      {users.length === 0 && businesses.length === 0 && (
        <p>No results found for your search.</p>
      )}
    </div>
    </>
  );
};

export default SearchResultsPage;
