import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "../styles/EditProfilePage.css";
import axios from "axios";

const EditProfilePage = () => {
  const [user, setUser] = useState(null);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    address: "",
    district: "",
  });
  const navigate = useNavigate();

  useEffect(() => {
    const authUser = JSON.parse(localStorage.getItem("authUser"));
    if (authUser && authUser.user) {
      setUser(authUser.user);
      setFormData({
        name: authUser.user.name || "",
        email: authUser.user.email || "",
        phone: authUser.user.phone || "",
        address: authUser.user.address || "",
        district: authUser.user.district || "",
      });
    } else {
      console.error("No user data found in localStorage");
    }
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSavePersonal = async () => {
    const nameValidationRegex = /^[A-Za-z\s]+$/; // Only alphabets and spaces
    if (!nameValidationRegex.test(formData.name)) {
      alert("Name must only contain letters and spaces.");
      return;
    }
  
    const token = localStorage.getItem("authToken");
    const userId = user ? user.id : null;
  
    if (!token || !userId) {
      console.error("No authentication token or user ID found");
      return;
    }
  
    try {
      const response = await axios.put(
        `https://apitest.kitezone.in/api/user/${userId}`,
        formData,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      console.log("Personal details updated:", response.data);
  
      const updatedUser = response.data.user;
      localStorage.setItem("authUser", JSON.stringify({ user: updatedUser }));
  
      setUser(updatedUser);
      navigate("/profile");
    } catch (error) {
      if (error.response) {
        console.error("Error Response:", error.response.data);
        alert("Validation Error: " + JSON.stringify(error.response.data.errors));
      } else {
        console.error("Unexpected Error:", error.message);
        alert("An unexpected error occurred.");
      }
    }
  };
  

  return (
    <div className="edit-profile-container">
      <h2>Edit Profile</h2>
      <form className="edit-profile-form" onSubmit={(e) => e.preventDefault()}>
        <label>
          Name:
          <input
            type="text"
            name="name"
            value={formData.name}
            onChange={handleInputChange}
          />
        </label>
        <label>
          Email:
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleInputChange}
          />
        </label>
        <label>
          Phone:
          <input
            type="text"
            name="phone"
            value={formData.phone}
            onChange={handleInputChange}
          />
        </label>
        <label>
          Address:
          <input
            type="text"
            name="address"
            value={formData.address}
            onChange={handleInputChange}
          />
        </label>
        <label>
          District:
          <input
            type="text"
            name="district"
            value={formData.district}
            onChange={handleInputChange}
          />
        </label>
        <button type="button" onClick={handleSavePersonal}>
          Save Changes
        </button>
      </form>
    </div>
  );
};

export default EditProfilePage;

